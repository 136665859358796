<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <el-input class="w220 mr10" v-model="keyWord" placeholder="文章标题" />
        <el-date-picker v-model="pickerValue" class="mr10" align="right" value-format="yyyy-MM-dd" type="daterange"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button type="primary" @click="$router.push('/isDrafts')">草稿箱</el-button>
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true">发布资讯</el-button>
        <el-button type="primary" @click="addArticlesList">添加推送</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" @handleSelectionChange="handleSelectionChange"
      :total="total">
      <template v-slot:table>
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="title" align="center" show-overflow-tooltip label="文章标题" />
        <el-table-column prop="originAuthor" align="center" label="来源" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.originAuthor ? scope.row.originAuthor : "——" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column prop="readTime" align="center" label="阅读时长" />
        <el-table-column prop="isDrafts" align="center" label="发布状态">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isDrafts == 1 ? false : true"
              @change="(e) => switchChange(e, scope.row.dynamicId)" active-text="发布" inactive-text="关闭">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isTop" align="center" label="是否置顶">
          <template slot-scope="scope">
            <el-switch :value="scope.row.isTop == 1 ? true : false"
              @change="(e) => switchChange(e, scope.row.dynamicId, 'stick')" active-text="是" inactive-text="否">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="del(scope.row.dynamicId)"
              icon="el-icon-info" icon-color="red" title="确定要删除这篇文章吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="发布文章"
      :visible.sync="centerDialogVisible" :destroy-on-close="true" width="50%" center>
      <postArticles @submitForm="submitForm" ref="postArticles" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "./components/postArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  selectDynamicList,
  updateNewsDynamicState,
  addNewsDynamicInfo,
  delNewsDynamicInfo,
  updateNewsDynamicTop,
} from "@/api/articleManagement";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    postArticles,
    customPopconfirm,
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
    };
  },
  created() {
    this.selectDynamicListAsync();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    /**@method 添加推送数据 */
    addArticlesList() {
      if (this.sendArticleData.length>0) {
        let dataLength = this.$store.state.sendArticleData.length + this.sendArticleData.length;
        if (dataLength > 8) {
          this.$message({
            type: "info",
            message: "推送的数据不能大于8条"
          })
          return;
        }
        this.$store.commit("addSendArticleData", this.sendArticleData)
        this.$message({
          type: "success",
          message: "添加成功，重复的数据会合并"
        })
      }else{
        this.$message({
          type: "info",
          message: "请选择要推送的文章"
        })
      }
    },
    /**@method 选择推送文章 */
    handleSelectionChange(data) {
      let sendArticleData = [];
      for (let row of data) {
        sendArticleData.push({
          title: row.title,
          id: row.dynamicId,
          optionType: 1,
          img: row.photos
        });
      }
      this.sendArticleData = sendArticleData;
    },
    /**@method 获取文章管理 */
    async selectDynamicListAsync(payload) {
      this.sendArticleData=[];
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDrafts: 0,
        companyId: this.userInfo.companyId,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectDynamicList(params);
        this.loading = false;

        const { data } = result;
        this.tableData = data.pageInfo.list;
        this.total = data.pageInfo.total;
      } catch (error) {
        //-console.log(error);
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.currentPage =1;
      this.pageSize = val;
      this.selectDynamicListAsync();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectDynamicListAsync();
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id, type) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1
      };
      if (type) {
        data = {
          dynamicId: id,
          isTop: e ? 1 : 0
        };
        updateNewsDynamicTop(data)
          .then((res) => {
            if (e) {
              this.$message.success("置顶成功！");
            } else {
              this.$message.success("取消置顶！");
            }
            this.selectDynamicListAsync();
          })
          .catch((err) => {
            if (err.code == 201) {
              //-console.log(err);
              this.$message.error(err.message);
            }
          });
      } else {
        await updateNewsDynamicState(data);
        await this.selectDynamicListAsync();
      }
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        startTime: this.pickerValue ? `${this.pickerValue[0]} 00:00:00` : "",
        endTime: this.pickerValue ? `${this.pickerValue[1]} 23:59:59` : "",
        title: this.keyWord,
      };
      this.selectDynamicListAsync(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        isDrafts: val.isDrafts ? 0 : 1,
        ...val,
      };
      try {
        await addNewsDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.selectDynamicListAsync();
      } catch (error) {
        //-console.log(error);
        this.$message.error(error.message);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop,
        isDrafts,
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop: isTop ? true : false,
        isDrafts: isDrafts ? true : false,
      };
      //-console.log(val);
      //-console.log(data);
      this.$nextTick(() => {
        this.$refs.postArticles.ruleForm = data;
        this.$refs.postArticles.fileList.push({ name: "", url: photos });
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delNewsDynamicInfo({ dynamicId: val });
        await this.selectDynamicListAsync();
      } catch (error) {
        //-console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>