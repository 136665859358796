import httpUtil from "@/utils/httpUtil";


/**获客文章获取 */
export const getDynamicInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getDynamicInfo", params);
export const selectDynamicList = async(params = {}) => { params.type = 4; return httpUtil.post("/api/crmPc/companyGoods/selectDynamicList", params) };

/**文章发布状态 */
export const updateDynamicState = async params => httpUtil.post("/api/crmPc/companyGoods/updateDynamicState", params);
export const updateNewsDynamicState = async params => { params.type = 4; return httpUtil.post("/api/crmPc/companyGoods/updateDynamicState", params); }
    /**文章顶置 */
export const updateDynamicTop = async params => httpUtil.post("/api/crmPc/companyGoods/updateDynamicTop", params);
export const updateNewsDynamicTop = async params => { params.type = 4; return httpUtil.post("/api/crmPc/companyGoods/updateDynamicTop", params); }

/**发布文章 */
export const addDynamicInfo = async(params, type) => httpUtil.post("/api/crmPc/companyGoods/addDynamicInfo", params, type);
export const addNewsDynamicInfo = async(params, type) => { params.type = 4; return httpUtil.post("/api/crmPc/companyGoods/addDynamicInfo", params, type) };

/**删除文章 */
export const delDynamicInfo = async params => httpUtil.post("/api/crmPc/companyGoods/delDynamicInfo", params);
export const delNewsDynamicInfo = async(params = {}) => { params.type = 4; return httpUtil.post("/api/crmPc/companyGoods/delDynamicInfo", params); }

/**员工数据 */
export const queryStaffData = params => httpUtil.post("/api/crmPc/customerEssay/queryStaffData", params);

/**获取员工列表 */
export const queryCompanyStaff = params => httpUtil.post("/api/crmPc/customerEssay/queryCompanyStaff", params);

/**访客 */
export const queryVisitorsData = params => httpUtil.post("/api/crmPc/customerEssay/queryVisitorsData", params);

/**员工数据详情 */
export const queryStaffShareDetail = params => httpUtil.post("/api/crmPc/customerEssay/queryStaffShareDetail", params);


export const queryAllType = params => httpUtil.post("/api/crmPc/essayType/queryAllType", params);


export const setCompanyNature = params => httpUtil.post("/api/crmPc/companyGoods/setCompanyNature", params);

export const getCompanyNature = companyId => httpUtil.post("/api/crmPc/companyGoods/getCompanyNature", { companyId: companyId });